import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ContentBottom } from '../../../components/content-bottom/content-bottom';
import { ThemeHelper } from '../../../view/modules/banners/theme-helper';

@Component({
  selector: 'app-banners',
  templateUrl: '../../../view/modules/banners/banners.component.html',
  styleUrls: ['../../../view/modules/banners/banners.component.scss'],
})
export class BannersComponent implements ContentBottom {
  public theme = new ThemeHelper;
  @Input() data: any;
  public ready: boolean;
  public rows = [];
  constructor() { }

  ngOnInit() {
    this.rows = this.data;
    setTimeout(() => {this.ready = true;}, 1);
  }

  ngAfterViewInit() {
          
  }
}

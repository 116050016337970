
import { Browser } from '@capacitor/browser';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Http, HttpResponse } from '@ocionic/request';
import { ToastController, LoadingController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { ModalController } from '@ionic/angular';
import { OptionsModalComponent } from '../controller/modules/options-modal/options-modal.component';
@Injectable({ 
  providedIn: 'root'
})
export class ApiRequestService {
  public host = "https://" + environment.host + "/index.php?"
  public response;
  public headers = window.localStorage.getItem('headers');
  public postData;
  public processing = false;
  public progress = 0;
  private file: File;
  constructor(public router: Router, private modalCtrl: ModalController, public loadingController: LoadingController, public toastController: ToastController) {
  }

  async fileUpload(route, event) {
    this.postData = new FormData()
    const reader = new FileReader();
    let contents;
    reader.onload = async (evt) => {
      contents = evt.target.result;
    };

    this.appendFormdata(event.target.files[0])
    reader.readAsDataURL(event.target.files[0])
    const loading = await this.loadingController.create({
      cssClass: 'ocionic-loader'
    });
    await loading.present();
    return new Promise(resolve => {
        const headers = this.headers;;
        let requestOptions = {};
        if (headers) {
          requestOptions = JSON.parse(headers)
        }
        
        requestOptions['content-type'] = "multipart/form-data"
        this.postData.append("file", contents);
        Http.post({
          'url': this.host + this.serialize(route),
          'data': this.postData,
          'headers': requestOptions
        }
        ).then(response => {
          loading.dismiss();
          if (response && response.data) {
            const resp = JSON.parse(response.data);
            this.processingResponse(resp, null, headers);
            loading.onDidDismiss().then(data => {
              resolve(resp)
            });
          }
        }).catch(error => {
          this.presentToast({text:"Something went wrong! Please check your connection or write to the administrator", color: 'danger', duration: 5000})
          return error;
        })

    }
    )
    return this.response
  } 


  async post(route, params, loader = false, progressBar = true) {
    if (progressBar) {
      this.processing = true;
      this.progress = 0;
    }
    this.postData = {}
    this.appendFormdata(params)
    let loading
    if (loader) {
      loading = await this.loadingController.create({
        cssClass: 'ocionic-loader'
      });
      await loading.present();
    }
    if (progressBar) {
      this.progress = 0.1;
    }
    return new Promise(resolve => {
     
        const headers = this.headers;;
        let requestOptions = {};
        if (headers) {
          requestOptions = JSON.parse(headers)
        }
        if (progressBar) {
          this.progress = 0.3;
        }
        requestOptions['content-type'] = "multipart/form-data"
        Http.post({
          'url': this.host + this.serialize(route),
          'data': this.postData,
          'headers': requestOptions
        }
        ).then(response => {
          if (progressBar) {
            this.progress = 0.5;
          }
          if (loader) {
            loading.dismiss();
          }
  
          if (response && response.data) {
            const resp = JSON.parse(response.data);
            this.processingResponse(resp, params, headers);
  
            if (progressBar) {
              this.progress = 1;
              setTimeout(() => {
                this.processing = false;
              }, 1000);
            }
            if (loader) {
              loading.onDidDismiss().then(data => {
                resolve(resp)
              });
            } else {
              resolve(resp)
            }
            
          }
  
          if (progressBar) {
            this.progress = 1;
            setTimeout(() => {
              this.processing = false;
            }, 1000);
          }

        }).catch(error => {
          this.presentToast({text:"Something went wrong! Please check your connection or write to the administrator", color: 'danger', duration: 5000})
          return error;
        })

    }
    )
    return this.response
  }

  async get(params = {}, loader = false, progressBar = true) {
    if (progressBar) {
      this.processing = true;
      this.progress = 0;
    }
    let loading
    if (loader) {
      loading = await this.loadingController.create({
        cssClass: 'ocionic-loader'
      });
      await loading.present();
    }
    if (progressBar) {
      this.progress = 0.1;
    }
    return new Promise((resolve, reject) => {
        const headers = this.headers;;
        let requestOptions = {};
        if (headers) {
          requestOptions = JSON.parse(headers)
        }
        if (progressBar) {
          this.progress = 0.3;
        }
        Http.get({
          'url': this.host + this.serialize(params),
          'headers': requestOptions
        }).then(response => {


          if (progressBar) {
            this.progress = 0.5;
          }
          if (loader) { 
            console.log(this.serialize(params))
            loading.dismiss();
          }
          if (response && response.data) {
            const resp = JSON.parse(response.data);
            this.processingResponse(resp, params, headers);
            if (progressBar) {
              this.progress = 1;
              setTimeout(() => {
                this.processing = false;
              }, 1000);
            }
            if (loader) {
              loading.onDidDismiss().then(data => {
                resolve(resp)
              });
            } else {
              resolve(resp)
            }
          }
          if (progressBar) {
            this.progress = 1;
            setTimeout(() => {
              this.processing = false;
            }, 1000);
          }
        }).catch(error => {
          this.presentToast({text:"Something went wrong! Please check your connection or write to the administrator", color: 'danger', duration: 5000})
          console.error( error);
          reject(error)
          return error;
        });
    }
    )
    return this.response
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p]) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  serializeForm(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p]) {
        str[p] = obj[p]
      }
    return str;
  }

  setHeader(key, value) {
    if (value && value != undefined) {
      const headers = this.headers;;

      let newHeaders = {};

      if (headers) {
        let oldHeaders = JSON.parse(headers);
        for (let key_old in oldHeaders) {
          newHeaders[key_old] = oldHeaders[key_old]
        }
      }
      newHeaders[key] = value;
      window.localStorage.removeItem('headers')
      window.localStorage.setItem('headers', JSON.stringify(newHeaders))
      this.headers = JSON.stringify(newHeaders);
    }
  }
  
  async presentToast(toastData) {
    let buttons = [];
    if (toastData.buttons) {
      for (let i = 0; i < toastData.buttons.length; i++) {
        const button = toastData.buttons[i];
        buttons.push({
          text: button.text,
          role: button.role,
          handler: () => {
            switch (button.role) {
              case 'external_link':
                this.router.navigate([button.link]);
                break;
              case 'internal_link':                
                Browser.open({ url: button.link, windowName: "_system" });
                break;            
              default:
                break;
            }            
          }
        })
      }
    }
    const toast = await this.toastController.create({
      message: toastData.text,
      color: toastData.color,
      duration: toastData.duration,
      position: toastData.position,
      buttons: buttons
    });
    toast.present();
  }
  appendFormdata(data, name = '') {
    if (typeof data === 'object') {
      for (let key in data) {
        if (name == '') {
          this.appendFormdata(data[key], key);
        } else {
          this.appendFormdata(data[key], name + '[' + key + ']');
        }
      }
    } else {
      this.postData[name] = data;
    }
  }
  async processingResponse(resp, params, headers) {
    if ((resp.auth_key && !headers) || (resp.auth_key && !JSON.parse(headers).Ocionickey)) {
      this.setHeader("Ocionickey", resp.auth_key)
    }

    if (params.product_id && resp.error && resp.error.option) {
      const modal = await this.modalCtrl.create({
        component: OptionsModalComponent,
        componentProps: {
          product_id: params.product_id
        },
        initialBreakpoint: 0.5,
        handle: true,
        breakpoints: [0.5, 0.99, 1]
      });

      modal.present().then(data => {
        setTimeout(function() {
          let elem = document.getElementById("optionsmodal-content");
          let elem2 = document.getElementById("addtocartoption");
          modal.addEventListener('touchend', ev => {
            setTimeout(function() {
              let info = elem.getBoundingClientRect()
              elem2.style.top = window.innerHeight - info.y - 50 + 'px'
            }, 500)
          });

          modal.addEventListener('touchmove', ev => {
            elem2 = document.getElementById("addtocartoption");
            elem2.style.top = window.innerHeight + 'px'
          });
        }, 1000)
      });
    }

    if (resp.redirect) {
     this.router.navigate([resp.redirect]);
    }
    if (resp.toast) {
     this.presentToast(resp.toast)
    }
  }
}

import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ContentBottom } from '../../../components/content-bottom/content-bottom';
import { ThemeHelper } from '../../../view/modules/slideshow/theme-helper';

@Component({
  selector: 'app-slideshow',
  templateUrl: '../../../view/modules/slideshow/slideshow.component.html',
  styleUrls: ['../../../view/modules/slideshow/slideshow.component.scss'],
})
export class SlideshowComponent implements ContentBottom {
  public theme = new ThemeHelper;
  @Input() data: any;
  public images = [];
  public ready: boolean;
  public slideOpts = {
    slidesPerView: 3,
    pager: false,
    pagination: false,
    slidesPerGroup: 1,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }
  }

  constructor() {
  }

  ngOnInit() {
    this.images = this.data.data.images
    this.slideOpts = this.data.data.slideOpts
    setTimeout(() => {this.ready = true;}, 1);
  }
}

import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ContentBottom } from '../../../components/content-bottom/content-bottom';
import { LanguageService } from '../../../providers/language.service'
import { ThemeHelper } from '../../../view/modules/related-path/theme-helper';

@Component({
  selector: 'app-related-path',
  templateUrl: '../../../view/modules/related-path/related-path.component.html',
  styleUrls: ['../../../view/modules/related-path/related-path.component.scss'],
})
export class RelatedPathComponent implements ContentBottom {
  public theme = new ThemeHelper;
  @Input() data: any;
  public list;
  public language: any;
  public ready: boolean;
  constructor(private lang: LanguageService) {
    this.language = this.lang.get
  }

  ngOnInit() {
    this.list = this.data;
    setTimeout(() => {this.ready = true;}, 1);
  }
}

// empty-host.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiRequestService } from '../../providers/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class EmptyHostGuard implements CanActivate {
  public host
  constructor(private req: ApiRequestService, private router: Router) {}

  canActivate(): boolean {
    if (!environment.host || environment.host === '') {
      this.router.navigate(['demo']);
      return false;
    }
    return true;
  }
}
import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ContentBottom } from '../../../components/content-bottom/content-bottom';
import { ThemeHelper } from '../../../view/modules/custom-html/theme-helper';

@Component({
  selector: 'app-custom-html',
  templateUrl: '../../../view/modules/custom-html/custom-html.component.html',
  styleUrls: ['../../../view/modules/custom-html/custom-html.component.scss'],
})
export class CustomHtmlComponent implements ContentBottom {
  public theme = new ThemeHelper;
  @Input() data: any;
  public ready: boolean;
  public html = [];
  constructor() { }

  ngOnInit() {
    this.html = this.data;
    setTimeout(() => {this.ready = true;}, 1);
  }
}

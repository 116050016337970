import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { ApiRequestService } from './api-request.service'

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(public req: ApiRequestService) {
    this.getFavoriteProduct();
    this.getStockProduct();
    this.getViewedProduct();
    this.getFavoriteCategory()
  }

  private event = new Subject<any>();
  public viewedProducts = [];
  public favoriteCategories = [];
  public favoriteProducts = [];
  public stockProducts = [];

  publishEvent(data: any) {
    this.event.next(data);
  }

  triggerEvent(): Subject<any> {
    return this.event;
  }

  addViewedProduct(product_id) {
    Preferences.get({ key: "viewedProducts" }).then(data => {
      let products = data.value != null ? JSON.parse(data.value) : null
      let viewedProducts = [];
      viewedProducts.push(product_id)
      if (products) {
        const index = products.indexOf(product_id);
        if (index > -1) {
          products.splice(index, 1);
        }
        viewedProducts = [...viewedProducts, ...products]
        this.viewedProducts = viewedProducts;
      }
      Preferences.set({ key: "viewedProducts", value: JSON.stringify(viewedProducts.slice(0, 20)) }).then(data => {
        this.publishEvent('viewedProducts')
      })
    });
  }


  addStockProduct(product_id) {
    return new Promise(resolve => {
      Preferences.get({ key: "stockProducts" }).then(data => {
        let products = data.value != null ? JSON.parse(data.value) : null
        let result = true;
        if (products) {
          const index = products.indexOf(product_id);
          if (index > -1) {
            products.splice(index, 1);
            result = false;
            this.req.get({ route: "api/ionic/catalog/removeStockProduct", product_id: product_id }, false);
          } else {
            this.req.get({ route: "api/ionic/catalog/addStockProduct", product_id: product_id }), false;
            products.unshift(product_id)
          }
        } else {
          products = [product_id]
        }
        this.stockProducts = products;
        Preferences.set({ key: "stockProducts", value: JSON.stringify(products) }).then(data => {
          this.publishEvent('stockProducts')
        })
        resolve(result)
      });
    })
  }

  addFavoriteProduct(product_id) {
    return new Promise(resolve => {
      Preferences.get({ key: "favoriteProducts" }).then(data => {
        let products = data.value != null ? JSON.parse(data.value) : null
        let result = true;
        if (products) {
          const index = products.indexOf(product_id);
          if (index > -1) {
            products.splice(index, 1);
            result = false;
            this.req.get({ route: "api/ionic/catalog/removeFavProduct", product_id: product_id }, false);
          } else {
            this.req.get({ route: "api/ionic/catalog/addFavProduct", product_id: product_id }, false);
            products.unshift(product_id)
          }
        } else {
          products = [product_id]
        }
        this.favoriteProducts = products;
        Preferences.set({ key: "favoriteProducts", value: JSON.stringify(products) }).then(data => {
          this.publishEvent('favoriteProducts')
        })
        resolve(result)
      });
    })
  }


  async addFavoriteCategory(category_id: string): Promise<boolean> {
    const { value } = await Preferences.get({ key: "favoriteCategories" });
    let categories = value ? JSON.parse(value) : null;
    let result = true;

    if (categories) {
      const index = categories.indexOf(category_id);

      if (index > -1) {
        categories.splice(index, 1);
        result = false;
        await this.req.get({ route: "api/ionic/catalog/removeFavCategory", category_id: category_id }, false);
      } else {
        categories.unshift(category_id)
        await this.req.get({ route: "api/ionic/catalog/addFavCategory", category_id: category_id }, false);
      }
    } else {
      categories = [category_id]
    }
    this.favoriteCategories = categories;

    await Preferences.set({ key: "favoriteCategories", value: JSON.stringify(categories) });
    this.publishEvent('favoriteCategories');

    return result;
  }

  getFavoriteProduct() {
    Preferences.get({ key: "favoriteProducts" }).then(data => {
      let products = data.value != null ? JSON.parse(data.value) : null
      if (products) {
        this.favoriteProducts = products;
      }
      this.publishEvent('favoriteProducts');
    });
  }

  getStockProduct() {
    Preferences.get({ key: "stockProducts" }).then(data => {
      let products = data.value != null ? JSON.parse(data.value) : null
      if (products) {
        this.stockProducts = products;
      }
      this.publishEvent('stockProducts')
    });
  }

  getViewedProduct() {
    Preferences.get({ key: "viewedProducts" }).then(data => {
      let products = data.value != null ? JSON.parse(data.value) : null
      if (products) {
        this.viewedProducts = products;   
      }
      this.publishEvent('viewedProducts')
    });
  }

  async getFavoriteCategory() {
    const { value } = await Preferences.get({ key: "favoriteCategories" });
    let categories = value ? JSON.parse(value) : null;
    if (categories) {
      this.favoriteCategories = categories;
    }
    this.publishEvent('favoriteCategories');
  }

  async checkFavoriteCategory(category_id: string): Promise<boolean> {
    const { value } = await Preferences.get({ key: "favoriteCategories" });
    let categories = value ? JSON.parse(value) : null;
    let result = false;

    if (categories) {
      const index = categories.indexOf(category_id);

      if (index > -1) {
        result = true;
      }
    }

    return result;
  }

  checkFavoriteProduct(product_id) {
    return new Promise(resolve => {
      Preferences.get({ key: "favoriteProducts" }).then(data => {
        let products = data.value != null ? JSON.parse(data.value) : null
        let result = false;
        if (products) {
          const index = products.indexOf(product_id);
          if (index > -1) {
            result = true;
          }
        }
        resolve(result);
      });
    })
  }

  checkStockProduct(product_id) {
    return new Promise(resolve => {
      Preferences.get({ key: "stockProducts" }).then(data => {
        let products = data.value != null ? JSON.parse(data.value) : null
        let result = false;
        if (products) {
          const index = products.indexOf(product_id);
          if (index > -1) {
            result = true;
          }
        }
        resolve(result);
      });
    })
  }

  isLogged() {
    return new Promise(resolve => {
      Preferences.get({ key: "customer" }).then(data => {
        let customer_info = data.value != null ? JSON.parse(data.value) : null
        if (customer_info) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    })
  }
}

import { TranslateService } from '@ngx-translate/core';
import { Component, Type, ViewChild, ComponentFactoryResolver, ViewContainerRef, OnInit, Input, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestService } from '../../../providers/api-request.service'
import { ContentBottomService } from '../../../providers/content-bottom.service'
import { ContentBottom } from '../../../components/content-bottom/content-bottom'
import { IonSlides, AlertController } from '@ionic/angular';
import { SessionService } from '../../../providers/session.service'
import { ModalController, NavParams } from '@ionic/angular';
import { ThemeHelper } from '../../../view/modules/options-modal/theme-helper';
@Component({
  selector: 'app-options-modal',
  templateUrl: '../../../view/modules/options-modal/options-modal.component.html',
  styleUrls: ['../../../view/modules/options-modal/options-modal.component.scss'],
})
export class OptionsModalComponent implements OnInit {
  public theme = new ThemeHelper;
  @ViewChild('cartBar', { read: ViewContainerRef }) cartBar;
  public productClass = 'grid-system';
  public filterRating = false;
  public ready: boolean;
  public classRating = [];
  public language: any;
  public optionErrors = [];
  public calcPrice;
  public isFavorite;
  public isStock;
  private file: File;
  public tabsConfig = {
    dragThreshold: 10,
    maxDragAngle: 400,
    sideMenuThreshold: 50,
    shortSwipeDuration: 180,
    transitionDuration: 300,
    debug: false,
    allowElementScroll: true,
    avoidElements: true
  };
  public radioOpts = {
    slidesPerView: 1.9,
    pager: false,
    pagination: false,
    slidesPerGroup: 0.5,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }
  }
  public categories;
  public headers;
  public product_id
  public product_info;
  public heading_title;

  @ViewChild('contentBottomContainer', { read: ViewContainerRef }) bottomContainer;
  @ViewChild('contentTopContainer', { read: ViewContainerRef }) topContainer;
  @ViewChild('contentMiddleContainer', { read: ViewContainerRef }) middleContainer;

  constructor(private modalCtrl: ModalController, private navParams: NavParams, public translate: TranslateService, private session: SessionService, private alertController: AlertController, public req: ApiRequestService, private contentBottomService: ContentBottomService, private resolver: ComponentFactoryResolver, private activatedRoute: ActivatedRoute, private router: Router) {
    this.product_id = this.navParams.data.product_id;
    this.loadPageData();
  }

  changeFavorite() {
    this.session.addFavoriteProduct(this.product_id).then(data => {
      this.isFavorite = data
    });
  }

  changeStock() {
    this.session.addStockProduct(this.product_id).then(data => {
      this.isStock = data
    });
  }

  loadPageData() {
    // let response = {}
    this.req.get({
      route: "api/ionic/catalog/getProduct",
      product_id: this.product_id
    }).then(response => {
      this.product_info = response.product_info;
      this.calcPrice = response.product_info.special ? response.product_info.special : response.product_info.price;
      this.heading_title = response.heading_title;
      this.loadModules(response.content_bottom, response.content_top, response.content_middle);
    })

  }
  onFileChange(event, key) {
    if (event.target.files[0]) {
      this.req.fileUpload({ route: 'tool/upload_ionic' }, event).then(data => {
        this.product_info.options[key].value = data.code;
      })
    } else {
      this.product_info.options[key].value = ""
    }

  }
  loadModules(content_bottom, content_top, content_middle) { }
  showRating(number) {
    this.filterRating = true;
    this.classRating = [];
    this.classRating[number] = "show"
  }
  async writeReview() {
    let header;
    await this.translate.get('add_review_label').subscribe(data => {
      header = data.value
    })
    let cancel_button;
    await this.translate.get('cancel_button').subscribe(data => {
      cancel_button = data.value
    })
    let send_review_button;
    await this.translate.get('send_review_button').subscribe(data => {
      send_review_button = data.value
    })
    let review_error_text;
    await this.translate.get('review_error_text').subscribe(data => {
      review_error_text = data.value
    })
    let review_error_name;
    await this.translate.get('review_error_name').subscribe(data => {
      review_error_name = data.value
    })

    let review_mark_5;
    await this.translate.get('review_mark_5').subscribe(data => {
      review_mark_5 = data.value
    })
    let review_mark_4;
    await this.translate.get('review_mark_4').subscribe(data => {
      review_mark_4 = data.value
    })
    let review_mark_3;
    await this.translate.get('review_mark_3').subscribe(data => {
      review_mark_3 = data.value
    })
    let review_mark_2;
    await this.translate.get('review_mark_2').subscribe(data => {
      review_mark_2 = data.value
    })
    let review_mark_1;
    await this.translate.get('review_mark_1').subscribe(data => {
      review_mark_1 = data.value
    })

    let review_text_placeholder;
    await this.translate.get('review_text_placeholder').subscribe(data => {
      review_text_placeholder = data.value
    })
    let review_name_placeholder;
    await this.translate.get('review_name_placeholder').subscribe(data => {
      review_name_placeholder = data.value
    })
    let review_select_rating;
    await this.translate.get('review_select_rating').subscribe(data => {
      review_select_rating = data.value
    })
    const alert = await this.alertController.create({
      header: header,
      buttons: [
        {
          text: cancel_button,
          handler: (data: any) => {
          }
        },
        {
          text: send_review_button,
          handler: (data: any) => {
            if (data[0].length < 2 || data[0].length > 50) {
              this.alertController.create({ header: review_error_name, buttons: ["OK"] }).then(res => { res.present() });
            } else if (data[1].length < 20 || data[1].length > 1000) {
              this.alertController.create({ header: review_error_text, buttons: ["OK"] }).then(res => { res.present() });
            } else {
              let reviewData = data;
              this.alertController.create({
                header: review_select_rating, buttons: [{
                  text: 'OK',
                  handler: (data: any) => {
                    reviewData[2] = data;
                    this.req.post({
                      route: "api/ionic/catalog/sendReview",
                      product_id: this.product_id
                    }, reviewData).then(response => {
                      this.alertController.create({ header: response.success, buttons: ["OK"] }).then(res => { res.present() });
                    })
                  }
                }], inputs: [
                  {
                    label: review_mark_5,
                    type: 'radio',
                    value: '5'
                  },
                  {
                    label: review_mark_4,
                    type: 'radio',
                    value: '4'
                  },
                  {
                    label: review_mark_3,
                    type: 'radio',
                    value: '3'
                  },
                  {
                    label: review_mark_2,
                    type: 'radio',
                    value: '2'
                  },
                  {
                    label: review_mark_1,
                    type: 'radio',
                    value: '1'
                  }
                ]
              }).then(res => { res.present() });

            }
          }
        }
      ],
      inputs: [
        {
          placeholder: review_name_placeholder
        },
        {
          type: 'textarea',
          placeholder: review_text_placeholder
        }
      ]
    });

    await alert.present();
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
  ngOnInit() {
  }
  setOptionValue(key, value) {
    this.product_info.options[key].value = value;
    this.optionErrors[key] = false;
    this.calculate()
  }
  setOptionSelectValue(key, event) {
    this.product_info.options[key].value = event.target.value;
    this.calculate()
  }
  checkboxOption(event, value, key) {
    if (!this.product_info.options[key].value) {
      this.product_info.options[key].value = []
    }
    if (event.target.checked) {
      this.product_info.options[key].value.splice(this.product_info.options[key].value.indexOf(value), 1);
    }
    if (!event.target.checked) {
      this.product_info.options[key].value.push(value);
    }

    if (this.product_info.options[key].value.length < 1) {
      this.product_info.options[key].value = ''
    }
    this.calculate()
  }
  calculate() {
    this.calcPrice = this.product_info.special ? this.product_info.special : this.product_info.price;
    for (var i = 0; i < this.product_info.options.length; i++) {
      if (this.product_info.options[i].type != 'checkbox') {
        for (let value of this.product_info.options[i].product_option_value) {
          if (value.price) {
            if (this.product_info.options[i].value == value.product_option_value_id) {
              if (value.price_prefix == '+') {
                this.calcPrice = parseFloat(this.calcPrice) + parseFloat(value.price);
              } else {
                this.calcPrice = parseFloat(this.calcPrice) - parseFloat(value.price);
              }
            }
          }
        }

      } else {
        // options.option[this.product_info.options[i].product_option_id] = [];
        for (let value of this.product_info.options[i].product_option_value) {
          if (value.price) {
            if (this.product_info.options[i].value.indexOf(value.product_option_value_id) >= 0) {
              if (value.price_prefix == '+') {
                this.calcPrice = parseFloat(this.calcPrice) + parseFloat(value.price);
              } else {
                this.calcPrice = parseFloat(this.calcPrice) - parseFloat(value.price);
              }
            }
          }
        }
      }
    }
    this.calcPrice = this.product_info.symbol_left + this.calcPrice + ' ' + this.product_info.symbol_right
  }
  addToCart() {
    this.optionErrors = [];
    let options = { option: [] };
    for (var i = 0; i < this.product_info.options.length; i++) {
      if (this.product_info.options[i].required == 1 && this.product_info.options[i].value.length < 1) {
        this.optionErrors[i] = true;
      } else {
        if (this.product_info.options[i].type != 'checkbox') {
          options.option[this.product_info.options[i].product_option_id] = this.product_info.options[i].value;
        } else {
          options.option[this.product_info.options[i].product_option_id] = [];
          for (let value of this.product_info.options[i].value) {
            options.option[this.product_info.options[i].product_option_id].push(value)
          }
        }
      }
    }
    if (!this.optionErrors.length) {
      this.req.post({
        route: "api/ionic/cart/add",
        product_id: this.product_id
      }, options).then(response => {
        this.session.publishEvent('cart');
        if (response.error_options) {
        }
      })
    }
  }

  addToCartRelated(product_id) {
    this.req.get({
      route: "api/ionic/cart/add",
      product_id: product_id
    }).then(response => {
      this.session.publishEvent('cart');
    })
  }
  disableScroll(e) {
    console.log(e); e.stopPropagation();
  }

  updateDateOption(id, ev){
    this.product_info.options[id].value = ev.detail.value
  }
  ngAfterContentInit() {
    this.ready = true;      
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ApiRequestService } from '../../../providers/api-request.service'
import { SessionService } from '../../../providers/session.service'
import { NavController } from '@ionic/angular';
import { LanguageService } from '../../../providers/language.service'
import { ThemeHelper } from '../../../view/common/product-thumb//theme-helper';

@Component({
  selector: 'product-thumb',
  templateUrl: '../../../view/common/product-thumb/product-thumb.component.html',
  styleUrls: ['../../../view/common/product-thumb/product-thumb.component.scss'],
})
export class ProductThumbComponent implements OnInit {
  @Input('product') product;
  @Input('config') config;
  public theme = new ThemeHelper;
  constructor(private lang: LanguageService, private session: SessionService, public req: ApiRequestService, public navCtrl: NavController) {  
  }

  ngOnInit() {
    this.product.iswish = this.session.favoriteProducts.indexOf(this.product.product_id) >= 0 ? true : false;
    this.product.iswatch = this.session.stockProducts.indexOf(this.product.product_id) >= 0 ? true : false;
  }

  addToCart(product_id) {
    this.req.get({
      route: "api/ionic/cart/add",
      product_id: product_id
    }, false, true).then(response => {
      if (!response.error) {
        this.session.publishEvent('cart');
      }
    })
  }
  changeWish(product_id) {
    this.session.addFavoriteProduct(product_id).then(data => {
      this.product.iswish = data
    });
  }
  changeStockWatch(product_id) {
    this.session.addStockProduct(product_id).then(data => {
      this.product.iswatch = data
    });
  }
}

import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ContentBottom } from '../../../components/content-bottom/content-bottom';
import { Router } from '@angular/router';
import { SessionService } from '../../../providers/session.service'
import { ApiRequestService } from '../../../providers/api-request.service'
import { LanguageService } from '../../../providers/language.service'
import { ThemeHelper } from '../../../view/modules/related-products/theme-helper';
import { ProductThumbComponent } from '../../common/product-thumb/product-thumb.component';

@Component({
  selector: 'app-related-products',
  templateUrl: '../../../view/modules/related-products/related-products.component.html',
  styleUrls: ['../../../view/modules/related-products/related-products.component.scss'],
})
export class RelatedProductsComponent implements ContentBottom {
  public theme = new ThemeHelper;
  @Input() productList = [];
  @Input() data: any;
  public title;
  public ready: boolean;
  public language: any;
  public template;
  public slideOpts = {
    slidesPerView: 3,
    pager: false,
    pagination: false,
    slidesPerGroup: 1,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }
  }
  public slideOptsDefault = {
    slidesPerView: 1,
    pager: false,
    pagination: false,
    slidesPerGroup: 1,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }
  }
  public num: 1
  public productsPerRow: 3
  constructor(private lang: LanguageService, public router: Router, private session: SessionService, private req: ApiRequestService) {
    this.language = this.lang.get

  }

  ngOnInit() {
    this.productList = this.data.data.products;
    this.template = this.data.data.template;
    if (this.data.data.slideOpts) {
      this.slideOpts = this.data.data.slideOpts;
    } else {
      this.slideOpts = {
        slidesPerView: 3,
        pager: false,
        pagination: false,
        slidesPerGroup: 1,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }
      }
    }
    if (Number.isInteger(this.data.data.productsPerRow)) {
      this.productsPerRow = this.data.data.productsPerRow;
    } else {
      this.productsPerRow = 3
    }
    this.title = this.data.data.title
    setTimeout(() => {this.ready = true;}, 1);
  }
  parseInt(int) {
    return Number(int)
  }


  addToCart(product_id) {
    this.req.get({
      route: "api/ionic/cart/add",
      product_id: product_id
    }).then(response => {
      if (!response.error) {
        this.session.publishEvent('cart');
      }
    })
  }
}

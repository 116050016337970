import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes,CanActivate } from '@angular/router';
import { EmptyHostGuard } from './demo/starter/demo.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule), 
    canActivate: [EmptyHostGuard]
  },
  {
    path: 'catalog/product-list/:path/:path_id',
    loadChildren: () => import('./controller/catalog/product-list/product-list.module').then(m => m.ProductListPageModule)
  },
  {
    path: 'catalog/product/:product_id',
    loadChildren: () => import('./controller/catalog/product/product.module').then(m => m.ProductPageModule)
  },
  {
    path: 'catalog/path-list/:id',
    loadChildren: () => import('./controller/catalog/path-list/path-list.module').then(m => m.PathListPageModule)
  },
  {
    path: 'auth-page',
    loadChildren: () => import('./controller/account/auth-page/auth-page.module').then(m => m.AuthPagePageModule)
  },
  {
    path: 'order-list',
    loadChildren: () => import('./controller/account/order-list/order-list.module').then(m => m.OrderListPageModule)
  },
  {
    path: 'order-info/:order_id',
    loadChildren: () => import('./controller/account/order-info/order-info.module').then(m => m.OrderInfoPageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./controller/account/address/address.module').then(m => m.AddressPageModule)
  },
  {
    path: 'address-form',
    loadChildren: () => import('./controller/account/address-form/address-form.module').then(m => m.AddressFormPageModule)
  },
  {
    path: 'account-form',
    loadChildren: () => import('./controller/account/account-form/account-form.module').then(m => m.AccountFormPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./controller/account/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'catalog/favorite-products/:type',
    loadChildren: () => import('./controller/catalog/favorite-products/favorite-products.module').then(m => m.FavoriteProductsPageModule)
  },
  {
    path: 'catalog/custom-page/:type/:id',
    loadChildren: () => import('./controller/catalog/custom-page/custom-page.module').then(m => m.CustomPagePageModule)
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/starter/starter.module').then(m => m.StarterPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ContentBottom } from '../../../components/content-bottom/content-bottom';
import { Router } from '@angular/router';
import { SessionService } from '../../../providers/session.service'
import { ApiRequestService } from '../../../providers/api-request.service'
import { LanguageService } from '../../../providers/language.service'
import { ThemeHelper } from '../../../view/modules/viewed-products/theme-helper';

@Component({
  selector: 'app-viewed-products',
  templateUrl: '../../../view/modules/viewed-products/viewed-products.component.html',
  styleUrls: ['../../../view/modules/viewed-products/viewed-products.component.scss'],
})
export class ViewedProductsComponent implements ContentBottom {
  public theme = new ThemeHelper;
  @Input() data: any;
  public products: any;
  public language: any;
  public ready: boolean;
  public slideOpts = {
    slidesPerView: 3.1,
    pager: false,
    pagination: false,
    slidesPerGroup: 1,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }
  }
  public heading_title;
  constructor(private lang: LanguageService, private req: ApiRequestService, private session: SessionService) {
    this.language = this.lang.get
    this.loadData();
    this.session.triggerEvent().subscribe((data) => {
      if (data == "viewedProducts") {
        this.loadData();
      }
    });
  }
  loadData() {
      this.req.post({ route: 'api/ionic/catalog/getProductsByIds', pattern: "yo" }, { product_id: this.session.viewedProducts }, false).then(response => {
        this.products = response.products;
        this.heading_title = this.data.data.heading_title
      })
  }
  ngOnInit() {
    setTimeout(() => {this.ready = true;}, 1);
  }
}

import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiRequestService } from '../../../providers/api-request.service'
import { LanguageService } from '../../../providers/language.service'
import { ThemeHelper } from '../../../view/modules/payment-modal/theme-helper';

@Component({
  selector: 'app-payment-modal',
  templateUrl: '../../../view/modules/payment-modal/payment-modal.component.html',
  styleUrls: ['../../../view/modules/payment-modal/payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
  public theme = new ThemeHelper;
  @ViewChild('iframe') iframe: ElementRef;
  @Input() order_id: any;
  public language: any;
  public ready: boolean;
  @Input() payment_method: any;
  public payUrl;
  public payUrl1;
  constructor(private lang: LanguageService, public req: ApiRequestService, public modalController: ModalController, private sanitizer: DomSanitizer) {
    this.language = this.lang.get
  }

  ngOnInit() {
    this.payUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.req.host + 'route=api/ionic/payment/' + this.payment_method + '/confirm&order_id=' + this.order_id);
  }


  ngAfterViewInit() {
    this.ready = true;      
  }

  closeModal() { this.modalController.dismiss(); }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Injectable } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppPreferences } from '@awesome-cordova-plugins/app-preferences/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { RelatedProductsComponent } from './controller/modules/related-products/related-products.component';
import { ViewedProductsComponent } from './controller/modules/viewed-products/viewed-products.component';
import { CustomHtmlComponent } from './controller/modules/custom-html/custom-html.component';
import { RelatedPathComponent } from './controller/modules/related-path/related-path.component';
import { QuickPathComponent } from './controller/modules/quick-path/quick-path.component';
import { SlideshowComponent } from './controller/modules/slideshow/slideshow.component';
import { PaymentModalComponent } from './controller/modules/payment-modal/payment-modal.component';
import { OptionsModalComponent } from './controller/modules/options-modal/options-modal.component';
import { SearchComponent } from './controller/modules/search/search.component';
import { BannersComponent } from './controller/modules/banners/banners.component';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { SuperTabs } from '@ionic-super-tabs/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { environment } from '../environments/environment';
import { ProductThumbComponent } from './controller/common/product-thumb/product-thumb.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    AppComponent,
    RelatedProductsComponent,
    ViewedProductsComponent,
    SlideshowComponent,
    SearchComponent,
    BannersComponent,
    ProductThumbComponent,
    OptionsModalComponent,
    RelatedPathComponent,
    QuickPathComponent,
    PaymentModalComponent,
    CustomHtmlComponent
  ],
  entryComponents: [],
  imports: [SuperTabsModule.forRoot(), BrowserModule, IonicModule.forRoot(), TranslateModule.forRoot(),
    HttpClientModule, LazyLoadImageModule,
    AppRoutingModule],
  providers: [InAppBrowser, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, AppPreferences,
    SuperTabs],
  bootstrap: [AppComponent],
})
export class AppModule { }

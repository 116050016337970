import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ContentBottom } from '../../../components/content-bottom/content-bottom';
import { Subject } from 'rxjs';
import { ApiRequestService } from '../../../providers/api-request.service'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LanguageService } from '../../../providers/language.service'
import { ThemeHelper } from '../../../view/modules/search/theme-helper';

@Component({
  selector: 'app-search',
  templateUrl: '../../../view/modules/search/search.component.html',
  styleUrls: ['../../../view/modules/search/search.component.scss'],
})
export class SearchComponent implements ContentBottom {
  public theme = new ThemeHelper;
  @Input() data: any;
  public searchText;
  public inputText;
  public language: any;
  public heading_title;
  public total;
  public products;
  public ready: boolean;
  public debounceTime = 300;
  public skeleton;
  public slideOpts = {
    slidesPerView: 3.1,
    pager: false,
    pagination: false,
    slidesPerGroup: 1,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }
  }

  inputValue = new Subject<string>();
  trigger = this.inputValue.pipe(
    debounceTime(this.debounceTime),
    distinctUntilChanged()
  ).subscribe(search => {
    this.req.get({ route: 'api/ionic/catalog/getProductsList', path: 'search', path_id: search, page: 1, limit: 10 }, false).then(data => {
      if (search && data.products) {
        this.products = data.products
        this.heading_title = data.page_info.name
        this.total = data.page_info.total;
      } else {
        this.heading_title = ''
        this.total = 0;
        this.products = []
      }
      this.skeleton = false;
    });
  });

  constructor(private lang: LanguageService, private req: ApiRequestService) {
    this.language = this.lang.get
  }
  ngOnInit() {
    this.searchText = this.data.data.heading_title
    setTimeout(() => {this.ready = true;}, 1);
  }
  search(event) {
    this.products = []
    this.inputText = event.target.value;
    if (this.inputText) {
      this.skeleton = true;
    }
    this.inputValue.next(event.target.value);
  }
}

import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ContentBottom } from '../../../components/content-bottom/content-bottom';
import { Router } from '@angular/router';
import { ApiRequestService } from '../../../providers/api-request.service'
import { SessionService } from '../../../providers/session.service'
import { ModalController } from '@ionic/angular';
import { OptionsModalComponent } from '../options-modal/options-modal.component';
import { ThemeHelper } from '../../../view/modules/quick-path/theme-helper';
@Component({
  selector: 'app-quick-path',
  templateUrl: '../../../view/modules/quick-path/quick-path.component.html',
  styleUrls: ['../../../view/modules/quick-path/quick-path.component.scss'],
})

export class QuickPathComponent implements OnInit {
  public theme = new ThemeHelper;
  @Input() data: any;
  public config;
  public page;
  public products = []
  public ready: boolean;
  public subcategories = []
  public selectedMain;
  public selectedChild;
  public childOptionError;
  public selectedPathId;
  public preloader;
  public lastPage = true;
  public selectedType;
  constructor(private modalCtrl: ModalController, private session: SessionService, private req: ApiRequestService) {
  }

  ngOnInit() {
    this.config = this.data.data;
    console.log(this.config)
    setTimeout(() => {this.ready = true;}, 1);
  }
  loadMore(event) {
    this.page = this.page + 1;
    this.lastPage = true;
    this.loadProducts()
  }

  changeWish(i, product_id) {
    this.session.addFavoriteProduct(product_id).then(data => {
      this.products[i].iswish = data
    });
  }

  addToCart(product_id) {
    this.req.get({
      route: "api/ionic/cart/add",
      product_id: product_id
    }).then(response => {
      if (!response.error) {
        this.session.publishEvent('cart');
      }
    })
  }

  selectCategory(index, path, path_id) {
    this.preloader = true;
    this.lastPage = true;
    this.page = 1;
    this.products = []
    this.subcategories = []
    this.selectedChild = "";
    if (this.selectedMain !== index) {
      this.selectedType = path;
      this.selectedPathId = path_id;
      this.selectedMain = index;
      this.loadProducts()
    } else {
      this.selectedMain = "";
      this.selectedChild = "";
      this.preloader = false;
    }
  }

  selectSubCategory(index, path, path_id) {
    this.preloader = true;
    this.lastPage = true;
    this.page = 1;
    this.products = []
    if (this.selectedChild !== index) {
      this.selectedType = path;
      this.selectedPathId = path_id;
      this.selectedChild = index;
      this.loadProducts()
    } else {
      this.selectedPathId = this.data.data.links[this.selectedMain].path_id;
      this.selectedChild = '';
      this.loadProducts()
    }
  }

  loadProducts() {
    this.req.get({
      route: "api/ionic/quickpath",
      path: this.selectedType,
      page: this.page,
      path_id: this.selectedPathId
    }, false).then(response => {

      if (response.categories && this.selectedChild === "") {
        this.subcategories = response.categories
      } else {
        // this.subcategories = []
      }
      if (response.products) {
        this.products = [...this.products,
        ...response.products]
        this.lastPage = false;
      } else {
        this.lastPage = true;
      }
      this.preloader = false;
    });
  }

  async openOptionModal(product_id) {
    const modal = await this.modalCtrl.create({
      component: OptionsModalComponent,
      componentProps: {
        product_id: product_id
      },
      initialBreakpoint: 0.5,
      handle: false,
      breakpoints: [0.5]
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();

  }
}

import { Injectable } from '@angular/core';
import { ContentBottomItem } from '../components/content-bottom/content-bottom-item';
import { RelatedProductsComponent } from '../controller/modules/related-products/related-products.component';
import { ViewedProductsComponent } from '../controller/modules/viewed-products/viewed-products.component';
import { CustomHtmlComponent } from '../controller/modules/custom-html/custom-html.component';
import { RelatedPathComponent } from '../controller/modules/related-path/related-path.component';
import { SlideshowComponent } from '../controller/modules/slideshow/slideshow.component';
import { SearchComponent } from '../controller/modules/search/search.component';
import { QuickPathComponent } from '../controller/modules/quick-path/quick-path.component';
import { BannersComponent } from '../controller/modules/banners/banners.component';

@Injectable({
  providedIn: 'root'
})
export class ContentBottomService {
  constructor() {
  }
  getModules(modules): ContentBottomItem[] {
    return this.getPageOrder(modules)
  }
  private getPageOrder(modules): ContentBottomItem[] {
    let result: ContentBottomItem[] = [];
    for (let one_module of modules) {
      let comp = this.resolveComponentsName(one_module.config.module)
      if (comp) {
        let newItem = new ContentBottomItem(comp, one_module.config)
        result.push(newItem)
      }
    }
    return result;
  }

  private resolveComponentsName(one_module) {
    switch (one_module) {
      case 'RelatedProducts': return RelatedProductsComponent;
      case 'ViewedProducts': return ViewedProductsComponent;
      case 'CustomHtml': return CustomHtmlComponent;
      case 'RelatedPath': return RelatedPathComponent;
      case 'QuickPath': return QuickPathComponent;
      case 'Slideshow': return SlideshowComponent;
      case 'Search': return SearchComponent;
      case 'Banners': return BannersComponent;
    }
  }
}

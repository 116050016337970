
import { Component } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() { 

    StatusBar.setStyle({ style: Style.Light });
    StatusBar.setBackgroundColor({
      color: '#FFFFFF'
     });

    //  NavigationBar.backgroundColorByName('white', true);
  }
}

import { Injectable, OnInit } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { ApiRequestService } from './api-request.service'
import { environment } from '../../environments/environment';
import { Device } from '@capacitor/device';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public language
  constructor(public req: ApiRequestService) {
    Preferences.get({ key: 'headers' }).then((headers) => { this.language = 'en' })
  }

  set(code) {
    fetch('./../assets/language/' + code + '.json').then(res => res.json())
      .then(json => {
        this.req.setHeader("language", code)
      }).catch(err => {
        this.req.setHeader("language", environment.language)
      });
  }

  async get() {
    return new Promise(resolve => {
      let headers = window.localStorage.getItem('headers');
      let data = headers ? JSON.parse(headers) : {};
      if (data && data.language) {
        fetch('./assets/i18n/' + data.language + '.json').then(res => res.json())
          .then(json => {
            this.req.setHeader("language", data.language)
            resolve(data.language)
          }).catch(err => {
            this.req.setHeader("language", environment.language)
            resolve(environment.language)
          })
      } else {
        Device.getLanguageCode().then(language => {
          fetch('./assets/i18n/' + language.value + '.json').then(res => res.json())
            .then(json => {
              this.req.setHeader("language", language.value)
              resolve(language.value)
            }).catch(err => {
              this.req.setHeader("language", environment.language)
              resolve(environment.language)
            })
        })
      }
      
    })
  }
}
